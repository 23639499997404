var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pt-4",attrs:{"flat":""}},[_c('v-layout',{staticClass:"mx-2",attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"mx-2",attrs:{"xs12":"","md12":""}},[_vm._v(" LEGENDS - (REMARKS- "),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"info"}},[_vm._v(" "+_vm._s(_vm.icons.mdiSwapHorizontal)+" ")]),_vm._v(" ),(REMARKS- "),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCurrencyUsd)+" ")]),_vm._v(" ) "),_c('h2',[_vm._v("Total: P "+_vm._s(_vm.total_amount)+" |Print "),(_vm.data_items.length>0 && !_vm.is_deleting)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.print_data()}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPrinter)+" ")]):_vm._e(),(_vm.is_deleting)?_c('v-progress-circular',{attrs:{"color":"info","indeterminate":""}}):_vm._e()],1)],1),_c('v-flex',{staticClass:"mx-2",attrs:{"xs12":"","md12":""}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.data_items,"height":"600"},scopedSlots:_vm._u([{key:"item.land_value",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.land_value>0?_vm.formatPrice(item.land_value):'')+" ")])])]}},{key:"item.existing_value",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.existing_value>0?_vm.formatPrice(item.existing_value):'')+" ")])])]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.formatPrice((parseFloat(item.land_value)-_vm.get_payment(item.inventory_payments))))+" ")])])]}},{key:"item.remarks",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.specify_remarks!=''?item.remarks+' ('+item.specify_remarks+')':'')+" ")])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.id>0)?_c('tr',[(item.remarks!='DAMAGE')?_c('td',{staticClass:"text-center"},[(!_vm.is_deleting)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"info"},on:{"click":function($event){return _vm.transfer(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiSwapHorizontal)+" ")]):_vm._e(),(_vm.is_deleting)?_c('v-progress-circular',{attrs:{"color":"info","indeterminate":""}}):_vm._e()],1):_vm._e(),_c('td',{staticClass:"text-center"},[(!_vm.is_deleting)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.payment(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiCurrencyUsd)+" ")]):_vm._e(),(_vm.is_deleting)?_c('v-progress-circular',{attrs:{"color":"info","indeterminate":""}}):_vm._e()],1)]):_vm._e()]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"30%"},model:{value:(_vm.is_change_bank_details),callback:function ($$v) {_vm.is_change_bank_details=$$v},expression:"is_change_bank_details"}},[_c('TransferData',{key:_vm.key,attrs:{"details":_vm.details},on:{"response":_vm.on_change}}),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.is_change_bank_details = false}}},[_vm._v(" Close")])],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"30%"},model:{value:(_vm.is_payment),callback:function ($$v) {_vm.is_payment=$$v},expression:"is_payment"}},[_c('PaymentData',{key:_vm.key,attrs:{"details":_vm.details,"data_items":_vm.details.inventory_payments},on:{"response":_vm.on_change}}),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.is_payment = false}}},[_vm._v(" Close")])],1),_c('snack-bar-dialog',{attrs:{"snackbar_flag":this.snackbar,"color":this.snackbar_color,"snackbar_text":this.snackbar_text}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }