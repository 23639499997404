<template>
  <div>
    <v-card>
      <v-form ref="form" class="multi-col-validation">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">PAYMENT DATA</h4></v-toolbar-title>
        </v-toolbar>

        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="month_of"
              dense
              label="Month Of"
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="date_released"
              dense
              label="Cheque Date"
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="date"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="voucher_no"
              dense
              label="Voucher #"
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="amount"
              dense
              label="Amount"
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-row>
          <!-- alert -->
          <v-col cols="12" v-show="alert">
            <v-alert color="warning" text class="mb-0">
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{ alert_message }}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn class="w-full" color="primary" @click="done_request" v-if="!saving_request">
              Done
            </v-btn>
            <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                 v-else></v-progress-circular>
          </v-col>
        </v-row>
        <v-flex xs12 md12 class="mx-2 mt-2">
          <v-data-table dense
                        :headers="headers"
                        :items="data_items"
          >
          </v-data-table>
        </v-flex>
      </v-form>
    </v-card>
  </div>
</template>
<script>
  import {mdiAlertOutline} from '@mdi/js'
  import {mapActions, mapGetters} from 'vuex'

  const initialState = () => {
    return {
      saving_request: false,
      alert: false,
      alert_message: '',

      date_released: '',
      reason: '',
      selection: '',
      category_id: '',
      month_of: '',
      voucher_no: '',
      branch_id: '',
      amount: '',
      specify: '',
      is_forbidden: false,

      branch_items: [],
      category_items: [],
      headers: [
        {text: 'ID', value: 'id', sortable: false},
        {text: 'Month Of', value: 'month_of', sortable: false},
        {text: 'Date', value: 'date', sortable: false},
        {text: 'Voucher #', value: 'voucher_no', sortable: false},
        {text: 'Amount', value: 'amount', sortable: false},
      ],
    }
  }
  export default {
    props: {
      details: Object,
      data_items: Array,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id']),
    },
    methods: {
      ...mapActions('inventory_payment', ['register_inventory_payment']),
      ...mapActions('branch_information', ['branch_data']),
      ...mapActions('category', ['list_of_all_categories']),
      initialize_data() {
        this.list_of_all_categories()
          .then(response => {
            this.category_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      async selected_category() {
        this.is_forbidden = true
        if (this.category_id === 4 || this.category_id === 3) {
          this.is_forbidden = false
        } else {
          this.selection = ''
        }
        this.branch_id = ''
        await this.branch_data({
          category_id: this.category_id,
          is_region: this.selection === 'Regional' ? 1 : 0,
          is_territory: this.selection === 'Territory' ? 1 : 0,
        })
          .then(response => {
            this.branch_items = response.data[0].active
          })
          .catch(error => {
            console.log(error)
          })
      },
      done_request() {
        this.saving_request = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('inventory_id', this.details.id)
          data.append('month_of', this.month_of)
          data.append('date', this.date_released)
          data.append('voucher_no', this.voucher_no)
          data.append('amount', this.amount)
          this.register_inventory_payment(data)
            .then(response => {
              this.$emit('response', response)
              this.saving_data = false
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_request = false
        }
      },
    },
  }
</script>
